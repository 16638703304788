import React from "react";
import Link from "next/link";
const plans = require("../plans");

const Prices = ({ currency }) => {
  return (
    <div className="columns">
      {plans
        .filter((p) => p.showOnHome)
        .map((plan) => {
          return (
            <div key={`plan-${plan.name}`} className="column">
              <Link passHref href={`/meets/new?package=${plan.name}`}>
                <div className=" notification paymentOption-container is-info is-light">
                  <div className="paymentOption">
                    <h1 className="title">{plan.title}</h1>
                    <p className="price">
                      {plan.cost[currency].priceText} <br />
                      <small>{plan.sizeText}</small>
                    </p>
                    <p>{plan.description}</p>
                    <span className="button is-large  get-started-button">
                      Get Started
                    </span>
                  </div>
                </div>
              </Link>
            </div>
          );
        })}
      <style jsx>{`
        .paymentOption-container:hover {
          background-color: #d9eefd;
          cursor: pointer;
        }
        .paymentOption-container:hover .button {
          background-color: #fffbeb;
          border-color: #b9b9b9;
        }
        .paymentOption {
          text-align: center;
          color: #666;
        }
        .paymentOption h1 {
          color: #444;
        }
        .paymentOption .button {
          margin-top: 1em;
        }
        .paymentOption-container {
          height: 100%;
          padding: 1.25em;
          padding-bottom: 100px;
        }
        .price-smaller {
          color: black;
          font-size: 1.5em;
          line-height: 1;
          font-weight: bold;
        }
        .price {
          color: black;
          font-size: 3em;
          line-height: 1;
          font-weight: bold;
        }
        .price small {
          font-size: 0.5em;
          font-weight: normal;
        }
        .get-started-button {
          width: calc(100% - 2.5em);
          position: absolute;
          bottom: 1.25em;
          left: 1.25em;
        }

        @media (max-width: 768px) {
          .hide-mobile {
            display: none;
          }
        }
      `}</style>
    </div>
  );
};

export default Prices;
