module.exports = [
  {
    showOnHome: true,
    maxSize: 4,
    isPaid: false,
    name: "trial",
    cost: {
      NZD: {
        price: 0,
        priceText: "Free",
      },
      AUD: {
        price: 0,
        priceText: "Free",
      },
      GBP: {
        price: 0,
        priceText: "Free",
      },
      EUR: {
        price: 0,
        priceText: "Free",
      },
      USD: {
        price: 0,
        priceText: "Free",
      },
    },
    title: "Trial Game",
    sizeText: "Trial Game (Up to 4 players)",
    description:
      "Want to check it out? Try out the game with a fixed set of races",
  },
  {
    showOnHome: true,
    maxSize: 10,
    paddleId: 663521,
    isPaid: true,
    name: "medium",
    cost: {
      NZD: {
        price: 10,
        priceText: "$10 NZD",
      },
      AUD: {
        price: 10,
        priceText: "$10 AUD",
      },
      GBP: {
        price: 5,
        priceText: "£5",
      },
      EUR: {
        price: 6,
        priceText: "€6",
      },
      USD: {
        price: 7,
        priceText: "$7 USD",
      },
    },
    title: "Get Together",
    sizeText: "Up to 10 players",
    description: "Get your mates together and get down the races",
  },
  {
    showOnHome: true,
    maxSize: 20,
    paddleId: 663529,
    isPaid: true,
    name: "large",
    cost: {
      NZD: {
        price: 20,
        priceText: "$20 NZD",
      },
      AUD: {
        price: 20,
        priceText: "$20 AUD",
      },
      GBP: {
        price: 10,
        priceText: "£10",
      },
      EUR: {
        price: 12,
        priceText: "€12",
      },
      USD: {
        price: 14,
        priceText: "$14 USD",
      },
    },
    title: "Party Time",
    sizeText: "Up to 20 players",
    description: "Perfect for birthday parties or team events.",
  },
  {
    showOnHome: true,
    maxSize: 500,
    paddleId: 662401,
    isPaid: true,
    name: "custom",
    cost: {
      NZD: {
        pricePerPlayer: 2,
        priceText: "$2 NZD / person",
      },
      AUD: {
        pricePerPlayer: 2,
        priceText: "$2 AUD / person",
      },
      GBP: {
        pricePerPlayer: 1,
        priceText: "£1 / person",
      },
      EUR: {
        pricePerPlayer: 1.2,
        priceText: "€1.20 / person",
      },
      USD: {
        pricePerPlayer: 1.4,
        priceText: "$1.40 / person",
      },
    },
    title: "It’s an Event!",
    sizeText: "Up to 500 players",
    description: "Customise your game to suit a large event or conference.",
  },
];
