import React, { useState, useEffect } from 'react';
const fetch = require('../fetch');
import Link from 'next/link';
import Layout from '../components/Layout';
import Prices from '../components/Prices';

const countries = require('../countries');

function Index(ctx) {
  const [currency, setCurrency] = useState('GBP');

  useEffect(() => {
    async function fetchData() {
      const countryResponse = await fetch.fetchWithSessionCookie(
        `/api/country`,
        ctx
      );
      if (countryResponse.status === 200) {
        const { countryCode } = await countryResponse.json();
        const country = countries.find((c) => c.code === countryCode);
        const currency = (country && country.currency) || 'GBP';
        setCurrency(currency);
      }
    }
    fetchData();
  }, []);

  return (
    <Layout
      brand={ctx.brand}
      showHeaderLinks={true}
      canonical="https://www.track-night.com"
    >
      <div>
        <div className="top-section">
          <div className="container">
            <div className="section">
              <div className="columns  is-vcentered">
                <div className="column has-text-centered">
                  <h1 className="title">Host your own horse racing event</h1>
                  <h2 className="subtitle">In person or online</h2>
                  <p>
                    Take part in the action from wherever you are with Track
                    Night, a game that takes you straight to the races.
                  </p>
                  <br />
                  <p className="emphasize">
                    <strong>All fun, no real gambling.</strong>
                  </p>
                </div>
                <div className="column max-half has-text-centered">
                  <picture>
                    <source
                      media="(max-width: 768px)"
                      srcSet="hero-image-small.jpg"
                    />
                    <img src="hero-image.jpg" alt="Track Night Game" />
                  </picture>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container rest">
          <div className="section">
            <h2 className="title">How it works</h2>
            <div className="columns reverse-columns is-vcentered">
              <div className="column">
                <ol className="howTo">
                  <li>
                    <div>
                      <h2 className="subtitle">Grab your mates</h2>
                      <p>
                        Connect the game to a TV or projector, or start a video
                        call and share the screen.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div>
                      <h2 className="subtitle">Create a Track Night game</h2>
                      <p>
                        Get everyone to join the game by pointing their phones
                        at the QR code.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div>
                      <h2 className="subtitle">Get your bets on</h2>
                      <p>
                        Place your bets, watch the races and see who comes top
                        of the leaderboard.
                      </p>
                    </div>
                  </li>
                </ol>
              </div>
              <div className="column">
                <iframe
                  id="ytplayer"
                  type="text/html"
                  width="100%"
                  height="300"
                  src="https://www.youtube.com/embed/DvRu6XO0xtE?autoplay=0"
                  frameBorder="0"
                ></iframe>
              </div>
            </div>
            <Prices currency={currency} />
            <div
              id="plans"
              className="has-text-centered notification nasty-text  is-warning is-light"
            >
              <Link passHref href="/custom-games">
                <div className="fakeLink">
                  <h1 className="title">Custom Games</h1>
                  <ul>
                    <li>Up to 1000 people</li>
                    <li>Add your company branding</li>
                    <li>Name the races</li>
                    <li>Choose the number of races</li>
                  </ul>
                  <br />
                  <div className="button is-large">Find out more</div>
                </div>
              </Link>
            </div>
          </div>
          {ctx.brand && ctx.brand.name === 'atr' ? (
            <div className="section">
              <h1 className="partnership">
                In partnership with At The Races and Sky Sports Racing <br />
                Bringing you the best horse racing from the UK
              </h1>
            </div>
          ) : null}
          <div className="section">
            <h1 className="title">Featured in</h1>
            <div className="columns">
              <div className="column has-text-centered">
                <img
                  className="feature-logo"
                  src="Metro-Logo.jpg"
                  title="The Metro"
                  alt="Metro logo"
                />
              </div>
              <div className="column has-text-centered">
                <img
                  className="feature-logo"
                  src="irish-post.png"
                  title="The Irish Post"
                  alt="Irish Post logo"
                />
              </div>
              <div className="column has-text-centered">
                <img
                  className="feature-logo"
                  src="swindon-advertiser.png"
                  title="The Swindon Advertiser"
                  alt="Swidon Advertiser logo"
                />
              </div>
            </div>
            <div className="columns">
              <div className="column has-text-centered">
                <img
                  className="feature-logo"
                  src="argus-logo.png"
                  title="The Argus"
                  alt="The Argus logo"
                />
              </div>
              <div className="column has-text-centered">
                <img
                  className="feature-logo"
                  src="sheffield-telegraph.png"
                  title="The Sheffield Telegraph"
                  alt="The Sheffield Telegraph logo"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <style jsx>{`
        html {
          scroll-behavior: smooth;
        }
        p {
          padding-bottom: 1em;
        }
        .padd-top {
          padding: 2em 0em;
        }
        .howTo div {
          left: 80px;
          position: relative;
          top: -39px;
          width: 75% !important;
        }
        .howTo h2 {
          display: block;
          width: 100%;
        }
        .howTo {
          list-style: none;
          counter-reset: item;
        }
        .howTo li {
          counter-increment: item;
          margin-bottom: 4px;
        }
        .howTo li:before {
          line-height: 1.2;
          transform: rotate(-5deg);
          content: counter(item);
          background: rgba(198, 198, 198, 0.8);
          border-radius: 100%;
          color: #fff;
          font-size: 2.5em;
          text-shadow: 2px 3px 0 rgba(181, 181, 181, 0.3);
          width: 1.4em;
          height: 1.4em;
          text-align: center;
          display: inline-block;
          font-family: 'Pacifico', cursive;
          position: relative;
          left: -7px;
          top: 12px;
        }

        @media (max-width: 550px) {
          .howTo div {
            left: 50px;
            top: -28px;
          }
          .howTo li:before {
            line-height: 1;
            font-size: 2em;
            text-shadow: 2px 3px 0 rgba(181, 181, 181, 0.3);
            width: 1.2em;
            height: 1.2em;
            left: -7px;
          }
        }
        .top-section {
          margin-bottom: 100px;
        }

        .partnership {
          font-size: 1.5em;
          text-align: center;
          font-style: italic;
          color: #888888;
        }

        .max-half {
          width: 50%;
        }
        @media (max-width: 768px) {
          .max-half {
            margin: auto;
            width: 100%;
          }
          .columns.is-vcentered {
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
          }
          .reverse-columns {
            flex-direction: column-reverse;
            display: flex;
          }
          .top-section {
            text-align: center;
          }
          .top-section-image {
            max-width: 100% !important;
          }
        }
        .rest {
          position: relative;
          top: -70px;
        }
        .emphasize {
          font-size: 1.2em;
        }
        .columns.is-vcentered {
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
        }
        .paymentOption-container a {
          text-decoration: none;
        }
        .paymentOption-container:hover {
          background-color: #fbf5dd;
        }
        .fakeLink:hover {
          cursor: pointer;
        }
        .nasty-text {
          color: #222 !important;
        }
        .nasty-text ul li {
          font-size: 1.2em;
        }
      `}</style>
    </Layout>
  );
}

export default Index;
